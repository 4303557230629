import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AfternoonIcon } from '../../assets/images/afternoon-icon.svg';
import { ReactComponent as AnytimeIcon } from '../../assets/images/clock-icon.svg';
import { ReactComponent as EveningIcon } from '../../assets/images/evening-icon.svg';
import { ReactComponent as MorningIcon } from '../../assets/images/morning-icon.svg';
import Button from '../../components/common/button/button';
import PhoneInput from '../../components/common/phone-input';
import { ModalContext } from '../../context/ModalContext';
import { getContactValueByType, getErrorDescription, initModal, setContactValueByType } from '../../helpers/utils';
import { updateProgress, updateQuoteContact } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';

const RequestSurvey = () => {
  const { modal, setModal } = useContext(ModalContext);

  const dispatch = useDispatch();

  const { content } = modal || {};
  const { customTheme, quoteDetails, decisions, token } = content || {};
  const { contact, engagement } = quoteDetails || {};
  const contactDetails = getContactValueByType(contact?.phones, 'contact_type', 'MOBILE', 'value') || {};

  const [decision] = decisions;

  const [isBookingCall, setIsBookingCall] = useState(false);
  const [isProgressing, setIsProgressing] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    country_code: contactDetails.country_code || '+44',
    mobile: contactDetails.value || '',
    preferredCallTime: [],
  });
  const { country_code, mobile, preferredCallTime } = customerDetails || {};
  const isMobileWithSpace = mobile?.includes(' ');

  const onUpdateCustomerDetails = (key, value) => {
    setCustomerDetails({ ...customerDetails, [key]: value });
  };

  useEffect(() => {
    ReactGA.event({
      category: 'SOLAR_QUOTE',
      label: 'QUOTE-NO',
      action: 'SURVEY',
    });
  }, []);

  useEffect(() => {
    const len = preferredCallTime.length;
    const isAnytimeSelected = preferredCallTime.includes('anytime');

    if (len === 3 && !isAnytimeSelected) {
      handlePreferredTimeSelection('anytime');
    } else if (len < 4 && isAnytimeSelected) {
      setCustomerDetails({
        ...customerDetails,
        preferredCallTime: preferredCallTime.filter(p => p !== 'anytime'),
      });
    }
  }, [preferredCallTime]);

  const handlePreferredTimeSelection = time => {
    const isSelected = preferredCallTime.includes(time);

    if (time === 'anytime') {
      if (isSelected) {
        setCustomerDetails({ ...customerDetails, preferredCallTime: [] });
      } else {
        setCustomerDetails({ ...customerDetails, preferredCallTime: ['morning', 'afternoon', 'evening', time] });
      }
      return;
    }

    if (isSelected) {
      setCustomerDetails(prev => ({
        ...customerDetails,
        preferredCallTime: prev.preferredCallTime.filter(p => p !== time),
      }));
    } else {
      setCustomerDetails(prev => ({
        ...customerDetails,
        preferredCallTime: [...prev.preferredCallTime, time],
      }));
    }
  };

  const getPreferredCallTimes = () => {
    if (preferredCallTime.includes('anytime')) {
      return [
        {
          is_primary: true,
          start_time: '08:00:00',
          end_time: '18:00:00',
        },
      ];
    }

    return preferredCallTime.map(time => {
      if (time === 'morning') {
        return {
          is_primary: false,
          start_time: '08:00:00',
          end_time: '12:00:00',
        };
      } else if (time === 'afternoon') {
        return {
          is_primary: false,
          start_time: '12:00:00',
          end_time: '16:00:00',
        };
      } else if (time === 'evening') {
        return {
          is_primary: false,
          start_time: '16:00:00',
          end_time: '18:00:00',
        };
      }
    });
  };

  const handleSave = () => {
    const mobilePhones = contact?.phones?.filter(p => p.contact_type === 'MOBILE') || [];
    const updatedContact = setContactValueByType(mobilePhones, 'contact_type', 'MOBILE', {
      value: null,
      country_code,
      contact_value: mobile?.replaceAll(' ', '')?.replaceAll('-', ''),
    });

    const call_times = getPreferredCallTimes();
    const request = {
      ...contact,
      addresses: null,
      emails: null,
      phones: updatedContact,
      call_times: call_times,
    };

    setIsBookingCall(true);
    dispatch(updateQuoteContact({ contact_id: contact.id, token, request }))
      .then(() => {
        const progressRequest = {
          decision: {
            id: decision.id,
          },
          stage: null,
          visible: null,
          owners: null,
        };
        setIsProgressing(true);
        dispatch(updateProgress({ engagement_id: engagement.id, token, request: progressRequest }))
          .then(() => {
            window.location = '/thank-you';
          })
          .catch(() => {})
          .finally(() => setIsProgressing(false));
      })
      .catch(error => {
        const errorText = getErrorDescription(error, 'Failed to book a call, please try again later');
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => setIsBookingCall(false));
  };

  return (
    <RequestSurveyWrapper customTheme={customTheme}>
      <label className="inter-700-text font-28 pb-6 border-bottom w-full flex flex-1">Request Survey</label>
      <p className="inter-400-text natural-500-text py-6">
        Enter your phone number and preferred called time below and we will give you a call to schedule your survey in
        as soon as possible!
      </p>
      <div className="flex-column w-full">
        <div className="mb-1">
          <label className="inter-500-text natural-900-text">Mobile</label>
        </div>
        <PhoneInput
          selectedCountry={country_code || '+44'}
          setSelectedCountry={value => onUpdateCustomerDetails('country_code', value)}
          phone={mobile}
          setPhone={value => onUpdateCustomerDetails('mobile', value)}
        />
      </div>
      <div className="flex-column w-full mt-6">
        <div className="mb-2">
          <label className="inter-500-text natural-900-text">Preferred call time</label>
        </div>
        <div className="gap-2 mb-2 tw-grid tw-grid-cols-2 md:tw-grid-cols-4">
          {[
            {
              id: 1,
              label: 'Morning',
              Icon: MorningIcon,
              time: 'morning',
            },
            {
              id: 2,
              label: 'Afternoon',
              Icon: AfternoonIcon,
              time: 'afternoon',
            },
            {
              id: 3,
              label: 'Evening',
              Icon: EveningIcon,
              time: 'evening',
            },
            {
              id: 4,
              label: 'Anytime',
              Icon: AnytimeIcon,
              time: 'anytime',
            },
          ].map(({ Icon, id, label, time }) => (
            <div
              key={id}
              onClick={() => handlePreferredTimeSelection(time)}
              className={classNames(
                'cursor flex-1 flex-column items-center bg-white tw-duration-300 tw-transition-all radius-1_5 py-1_5 px-3',
                preferredCallTime.includes(time) ? 'border-blue primary-text' : 'border-natural-200 natural-700-text',
              )}>
              <div className="flex items-center justify-center pxy-1">
                <Icon height={16} width={16} />
              </div>
              <div>
                <label className="inter-500-text !tw-text-inherit font-12 line-height-20">{label}</label>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between col-gap-4 flex-1 items-end mt-10">
        <Button label="Cancel" className="primary-grey !tw-w-full" onClick={() => setModal(initModal)} size="large" />
        <Button
          label="Request call"
          className="primary btn-primary !tw-w-full"
          onClick={handleSave}
          size="large"
          loading={isBookingCall || isProgressing}
          disabled={
            !preferredCallTime.length || !mobile || (isMobileWithSpace ? mobile.length < 12 : mobile.length < 10)
          }
        />
      </div>
    </RequestSurveyWrapper>
  );
};

const RequestSurveyWrapper = styled.div`
  width: min(456px, 100%);
  max-width: 456px;
  padding: 40px;

  .btn-primary {
    background-color: ${({ customTheme }) => customTheme.color.button};
    border-color: ${({ customTheme }) => customTheme.color.button};
  }
`;

export default RequestSurvey;
